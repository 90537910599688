<template>

    <v-chip
        :class="[this.id ? '' : 'person-label-def-cursor', 'person-label']"
        small
        label
        :color="isEsedo === false ? 'red lighten-4' : ''"
        @click="produceGlobalEvent"
    >
        <div
            class="if-more-tooltip-chip"
            v-tooltip="{ content: isEsedo === false ? `<i>${$t('Документ_не_может_быть_отправлен_этому_получателю')}</i>` : null }"
        >
            <v-icon :class="iconClass" :color="iconColor">{{ icon }}</v-icon>
            <div :class="textLineThrough ? 'text-decoration-line-through' : ''" :style="{ color: main ? '#E43958' : '' }">{{ Name.toLowerCase() }}</div>
        </div>    
        <div class="pl-actions-wrapper">
            <span
                v-if="canDelete"
                v-tooltip.top="$t('Удалить')"
                @click.stop="onDelete"
            >
                <i class="fas fa-trash dark-gray-color"></i>
            </span>
        </div>
    </v-chip>

</template>

<script>

export default {
    name: "WorkplaceChip",
    props: {
        id: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        formatter: {
            type: Function,
            default: null
        },
        iconColor: {
            type: String,
            default: "#494F59"
        },
        iconClass: {
            type: String,
            default: ""
        },
        icon: {
            type: String,
            default: "fas fa-user"
        },
        main: {
            type: Boolean,
            default: false
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        isEsedo: {
            type: Boolean,
            default: true
        },
        delFunc: {
            type: Function,
            default: null
        },
        isEmployee: {
            type: Boolean,
            default: false
        },
        disableInfo: {
            type: Boolean,
            default: false
        },
        textLineThrough: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        Name () {
            if (this.isEmployee)
                return this.name;

            if (this.formatter)
                return this.formatter(this.name);

            return this.sanitizeName(this.name);
        }
    },
    methods: {
        produceGlobalEvent(e) {
            e.stopPropagation();

            if (this.id && this.disableInfo === false)
                this.$eventBus.$emit('show-workplace-information', this.id);
        },
        async onDelete() {
            if (this.id) {
                if (this.delFunc){
                    await this.delFunc(this.id);
                }
                else {
                    this.$notify.confirm(
                        this.$t('Вы_действительно_хотите_удалить_получателя_данного_документа'),
                        async () =>
                        {
                            await this.$store.dispatch('global/actionsource/deleteRecipient', this.id);
                        }
                    );
                }
            }
        },
        sanitizeName(source)
        {
            if (!source)
                return "";
                
            if (source.indexOf('(') > -1)
            {
                let splitResult = source.split('(');

                if (splitResult.length <= 0)
                    return source;

                source = splitResult[0].trim();
            }

            if (source.indexOf(' ') == 0)
            {
                source = source.substring(1);
            }

            let detailedName = source.split(' ');

            if (detailedName.length <= 0)
                return source;

            let resultName;
            detailedName.forEach(function(namePart, index) {
                if (index === 0)
                    resultName = namePart;
                else
                    resultName += namePart[0] ? ` ${namePart[0]}.` : '';
            });

            return resultName;
        }
    }
}
</script>